<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          Mer informasjon om abonnement
        </h1>
        <div class="ff-flama clearfix mb-3">
          <p class="article-body mb-5">
            Europower er fornybarbransjens ledende kanal for nyheter og bakgrunn. Vi leverer daglige nyheter, et rikholdig nyhetsarkiv, reportasjer og bakgrunnsstoff, samt en omfattende database med detaljinformasjon om alle norske kraftselskaper. Vi kan tilby tilpassede innholdspakker til deg og din bedrift, basert på dine behov.
          </p>
          <p class="article-body mb-5">
            Fyll ut skjemaet nedenfor, så tar vi kontakt for en uforpliktende prat.
          </p>
          <form
            @submit="handleSubmit"
            @reset="resetFields"
            method="POST"
            class="subscription-form pb-3 needs-validation"
            novalidate>
            <fieldset>
              <legend class="ff-flama fs-lg text-center py-4 mb-3">
                Ja, jeg ønsker mer informasjon om abonnement
              </legend>

              <row :class="['m-0', {'d-none': formSubmitted}]">
                <column
                  xs="12"
                  lg="8">
                  <div class="form-group">
                    <label for="company">Selskap</label>
                    <input
                      id="company"
                      v-model="fields.company"
                      type="text"
                      name="company"
                      class="form-control"
                      required>
                  </div>

                  <div class="form-group">
                    <label for="name">Navn</label>
                    <input
                      id="name"
                      v-model="fields.name"
                      type="text"
                      name="name"
                      class="form-control"
                      required>
                  </div>

                  <div class="form-group">
                    <label for="address">Postadresse</label>
                    <input
                      id="address"
                      v-model="fields.address"
                      type="text"
                      name="address"
                      class="form-control"
                      required>
                  </div>

                  <row>
                    <column xs="6">
                      <div class="form-group">
                        <label for="post0">Postnummer</label>
                        <input
                          id="post0"
                          v-model="fields.post0"
                          type="text"
                          name="post0"
                          class="form-control"
                          required>
                      </div>
                    </column>
                    <column xs="6">
                      <div class="form-group">
                        <label for="post1">Sted</label>
                        <input
                          id="post1"
                          v-model="fields.post1"
                          type="text"
                          name="post1"
                          class="form-control"
                          required>
                      </div>
                    </column>
                  </row>

                  <div class="form-group">
                    <label for="email">E-post</label>
                    <input
                      id="email"
                      v-model="fields.email"
                      type="email"
                      name="email"
                      class="form-control"
                      required>
                  </div>

                  <div class="form-group">
                    <label for="phone">Telefon</label>
                    <input
                      id="phone"
                      v-model="fields.phone"
                      type="tel"
                      name="phone"
                      class="form-control"
                      required>
                  </div>

                  <div class="form-group">
                    <label for="numSubscriptions">Antall ansatte</label>
                    <input
                      id="numSubscriptions"
                      v-model="fields.numSubscriptions"
                      type="number"
                      min="1"
                      value="1"
                      name="numSubscriptions"
                      class="form-control"
                      required>
                  </div>

                  <div class="form-check my-5">
                    <input
                      id="acceptPrintMagazine"
                      v-model="fields.acceptPrintMagazine"
                      type="checkbox"
                      name="acceptPrintMagazine"
                      value="false"
                      class="form-check-input">
                    <label
                      for="acceptPrintMagazine"
                      class="form-check-label ml-2 ff-flama fs-md">
                      Ja takk, jeg ønsker å bli kontaktet
                    </label>
                  </div>
                  <Recaptcha
                    ref="recaptcha"
                    @gRtoken="handleRecaptchaTokenGenerateEvent"
                    @gRtokenExpired="handleResetRecaptchaEvent"
                    style="margin: 0 0 10px 0"/>
                  <row>
                    <column xs="6">
                      <input
                        :disabled="disableSubmitButton"
                        type="submit"
                        value="Send"
                        class="btn btn-primary w-100">
                    </column>
                    <column xs="6">
                      <input
                        type="reset"
                        value="Avbryt"
                        class="btn btn-secondary w-100">
                    </column>
                  </row>
                </column>
                <column
                  xs="12"
                  lg="4"
                  class="pt-4">
                  <div class="alert alert-light mt-1 mb-3">
                    <p>
                      Europower dekker hele bredden av kraft- og energispørsmål. Markeds- og prisutvikling, digitalisering av bransjen, politikk og rammebetingelser, utbygging, nettutfordringer, økonomi og grønt næringsliv er noen av temaene som dekkes.
                    </p>
                    <p>
                      Hold deg og din bedrift oppdatert og bruk tjenesten som verktøy for å forstå hva som skjer i bransjen.
                    </p>
                  </div>
                  <div class="alert alert-light">
                    <p>
                      For mer informasjon, ring oss på telefon + 47 22 00 11 50 eller <a href="mailto:marked@europower.no">marked@europower.no</a>
                    </p>
                  </div>
                </column>
              </row>
              <row
                :class="['d-none', {'d-flex': formSubmitted}]"
                class="m-0">
                <column xs="12">
                  <div class="alert alert-light mt-1 mb-3 text-center">
                    <h1 class="fs-xl fw-medium mb-3">Takk for henvendelsen</h1>
                    <p class="mb-4">
                      Vi har mottatt henvendelsen og vil kontakte deg så snart som mulig.
                    </p>
                  </div>
                  <div class="alert alert-light mb-0">
                    <h2 class="fs-lg fw-medium mt-0 mb-2">Spørsmål?</h2>
                    <p>
                      Ring oss på telefon + 47 22 00 11 50 eller send mail til <a href="mailto:marked@europower.no">marked@europower.no</a>
                    </p>
                  </div>
                </column>
              </row>

            </fieldset>
          </form>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import axios from 'axios';
import { Page } from 'global-components';
export default {
  name: 'subscription-page',
  extends: Page,
  data: function () {
    return {
      formSubmitted: false,
      fields: {
        company: '',
        name: '',
        address: '',
        post0: '',
        post1: '',
        email: '',
        phone: '',
        numSubscriptions: '1',
        acceptPrintMagazine: false
      },
      recaptchaToken: '',
      disableSubmitButton: true
    };
  },
  computed: {
    content() {
      var message = `Selskap: ${this.fields.company}<br>`;
      message += `Name: ${this.fields.name}<br>`;
      message += `Adresse: ${this.fields.address}<br>`;
      message += `Postnummer: ${this.fields.post0}<br>`;
      message += `Poststed: ${this.fields.post1}<br>`;
      message += `E-post: ${this.fields.email}<br>`;
      message += `Telefon: ${this.fields.phone}<br>`;
      message += `Antall ansatte: ${this.fields.numSubscriptions}<br>`;
      if (this.fields.acceptPrintMagazine) {
        message += 'Ja';
      } else {
        message += 'Nei';
      }
      message += ' takk, jeg ønsker å bli kontaktet.';
      return message;
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      e.stopPropagation();

      if (e.target.checkValidity() === true) {
        const postData = {
          to: ['marked@europower.no', this.fields.email],
          subject: 'Bestilling',
          html: this.content,
          gRecaptchaToken: this.recaptchaToken
        };

        axios.post('/send', postData)
          .then(() => {
            this.formSubmitted = true;
            this.isRecaptchaTokenExpired = true;
            this.$refs.recaptcha.resetRecaptcha();
            this.resetFields();
          })
          .catch((e) => {
            alert('Email(s) could not be sent this time !!');
            this.isRecaptchaTokenExpired = true;
            this.$refs.recaptcha.resetRecaptcha();
            this.resetFields();
          });
      }
      e.target.classList.add('was-validated');
    },
    handleResetRecaptchaEvent() {
      this.disableSubmitButton = true;
    },
    handleRecaptchaTokenGenerateEvent(token) {
      this.recaptchaToken = token;
      this.disableSubmitButton = false;
    },
    resetFields() {
      for (const el in this.fields) {
        this.fields[el] = '';
      }
    }
  }
};
</script>
